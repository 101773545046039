import { render, staticRenderFns } from "./addMyCourseManage.vue?vue&type=template&id=dc044e80&scoped=true"
import script from "./addMyCourseManage.vue?vue&type=script&lang=js"
export * from "./addMyCourseManage.vue?vue&type=script&lang=js"
import style0 from "./addMyCourseManage.vue?vue&type=style&index=0&id=dc044e80&prod&lang=less"
import style1 from "./addMyCourseManage.vue?vue&type=style&index=1&id=dc044e80&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc044e80",
  null
  
)

export default component.exports